<template>
    <section>
        <div class="row mx-0 mt-4 mb-2 justify-center">
            <div class="col-12 col-xl-10">
                <label class="f-15 text-muted2">
                    Seleccionar la ciudad de  {{ estado }}
                </label>
                <el-select v-model="id_ciudad" class="w-100" filterable remote reserve-keyword placeholder="Seleccione o digíte un estado" :remote-method="remoteMethodCiudad" :loading="loading" @change="ingresarCiudad">
                    <el-option v-for="item in estados" :key="item.id" :label="item.ciudad" :value="item.id" :disabled="item.disabled" />
                </el-select>
            </div>
        </div>
        <div class="col-12">
            <div class="row mx-0 px-3">
                <div v-for="(item, idx) in guardados" :key="idx" class="bg-gr-red px-2 br-12 d-middle-center text-white my-2 mx-1" style="height:32px;">
                    {{ item.ciudad }} <i class="icon-cancel cr-pointer" @click="eliminarLugar(item)" />
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Proyectos from '~/services/proyectos/proyectos'
import {mapGetters} from 'vuex'

export default {
    props:{
        idEstado:{
            type: Number,
            default: 0
        },
        estado:{
            type: String,
            default: ''
        }
    },
    data(){
        return{
            estados: [],
            loading: false,
            guardados: [],
            id_ciudad: null
        }
    },
    computed:{
        ...mapGetters({
            id_proyecto: 'proyectos/proyectos/id_proyecto'
        })
    },
    mounted(){
        this.listarCiudadesGuardadas()
        this.buscador_ciudades()
    },
    methods:{
        async remoteMethodCiudad(texto){
            this.delay(this.buscador_ciudades(texto))
        },
        async listarCiudadesGuardadas(){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    id_estado: this.idEstado 
                }
                const {data} = await Proyectos.listarCiudadesProyecto(params)
                this.guardados = data.ciudades
            } catch (e){
                this.error_catch(e)
            }
        },
        async buscador_ciudades(texto= null){
            try {
                const params = {
                    id_proyecto: this.id_proyecto,
                    texto,
                    id_estado: this.idEstado 

                }
                this.loading = true
                const {data} = await Proyectos.buscadorCiudades(params)
                this.loading = false
                this.estados = data.ciudades.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async ingresarCiudad(){
            try {
                const enviar = {
                    id_proyecto: this.id_proyecto,
                    id_ciudad:  this.id_ciudad 
                }
                const {data} = await Proyectos.ingresarCiudad(enviar)
                this.notificacion('Mensaje', 'Ciudad ingresada', 'success')
                this.listarCiudadesGuardadas()

            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarLugar(item){
            try {
                const {data} = await Proyectos.eliminarLugar(item.id_pro)
                this.notificacion('Mensaje', 'Lugar eliminado', 'success')
                this.listarCiudadesGuardadas()
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>